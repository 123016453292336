<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px; overflow: auto; max-height: 85%;">
    <div class="relative bg-white">
      <div>
        <div class="bg-primary flex justify-between p-3">
          <div class=" font-medium text-white heading-3">
            {{paymentId > 0 ? 'Edit Payment Method' : 'Add New Payment Method'}}
          </div>
        </div>
        <div class="p-4">
         <div>
          <div class="mb-4">
                  <TextField
                  :inputId="'taxinputnameid'"
                  :textMaxlength="50"
                  :showcharLimit="true"
                  :fieldError="nameErr !== ''"
                  :inputext="addUpObj.paymentMethodName"
                  :placholderText="`Payment Method Name`"
                  :lableText="'Payment Method Name'"
                  :autoFocus="false"
                  @keyPressAction="nameErr = ''"
                  @inputChangeAction="(data) => addUpObj.paymentMethodName = data"  />
                  <p v-if="nameErr !== ''" class="text-error heading-7">{{nameErr}}</p>
              </div>
         </div>
        </div>
      </div>
        <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItemsTax()"/>
          </div>
          </div>
      </div>
  </div>
</div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
components: {
  Button,
  TextField,
},
props: ['paymentId', 'paymentDetail'],
data () {
  return {
    addUpObj: {
      paymentMethodId: 0,
      paymentMethodName: '',
      dispalyOrder: 0,
    },
    nameErr: ''
  }
},
 created() {
},
mounted () {
  if (this.paymentId > 0) {
    if (this.paymentDetail !== null) {
      this.addUpObj.paymentMethodId = this.paymentDetail.paymentMethodId
      this.addUpObj.paymentMethodName = this.paymentDetail.paymentMethodName
      this.addUpObj.dispalyOrder = this.paymentDetail.dispalyOrder
    }
  }
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  discard () {
      document.body.style = 'overflow: visible;'
    this.$root.$emit('addEditPaymentComp', true, null)
  //   this.$root.$emit('addNewTaxHandler', true, [{taxId: 35,taxName: "Local G tax",taxValue: 4,isActive: true},{taxId: 7,taxName: "Electronic  CGST",taxValue: 14,isActive: true}])
  },
  saveItemsTax () {
    if (this.addUpObj.paymentMethodName === '') {
      this.nameErr = 'Payment method name is required'
    }
    if (this.addUpObj.paymentMethodName !== '') {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.addUpdatePaymethod(
        this.addUpObj,
        response => {
          this.$root.$emit('addEditPaymentComp', true, response.Data)
          document.body.style = 'overflow: visible;'
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
      
  },
},
beforeDestroy () {
  // this.$root.$off('closeConfirmBoxDialog')
}
}
</script>
<style scoped>

</style>