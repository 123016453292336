<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Payment Method ({{totalCount}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-end items-center">
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add New'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="paymentList.length > 0">
          <draggable :list="paymentList" group="people" @start="drag = true" @end="dragArray" id='list'>
            <div v-for="(data, index) in paymentList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.paymentMethodId, data)">
              <div class="flex items-center">
                <p class="text-gray2 heading-5"><i class="fas fa-grip-vertical"></i></p>
                <p class="text-gray4 heading-5 pl-3">{{data.paymentMethodName}}</p>
              </div>
            </div>
          </draggable>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>  
      </div>
    </div>
    <setting-add-edit-payment v-if="addEditPayment" :paymentId="selectedPaymentId" :paymentDetail="selectedDetail"/>
   </div>
</template>
<script>
import draggable from 'vuedraggable'
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import SettingAddEditPayment from './components/settingAddEditPayment.vue'
// import AddEditTax from './addEditTax.vue'
export default {
  components: {
    draggable,
    NoRecords,
    Button,
    SettingAddEditPayment,
    // AddEditTax
  },
  mixins: [deboucneMixin],
  data() {
    return {
      drag: false,
      paymentList: [],
      addEditPayment: false,
      totalCount: 0,
      selectedPaymentId: 0,
      selectedDetail: {},
    };
  },
  created() {},
  mounted() {
    this.getPaymentList()
    this.$root.$on("addEditPaymentComp", (response) => {
      console.log(response);
      if (response) {
        this.getPaymentList()
        this.addEditPayment = false
      }
      this.addEditPayment = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
    this.$root.$off('addEditPaymentComp')
  },
  methods: {
    openDetail (Id, data) {
      this.selectedPaymentId = Id
      this.selectedDetail = data
      this.addEditPayment= true
    },
    addNewCust () {
      this.selectedPaymentId = 0
      this.selectedDetail = {}
      this.addEditPayment= true
    },
    getPaymentList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.paymentmethodList(
          response => {
            this.paymentList = response.Data !== null ? response.Data : []
            this.totalCount = this.paymentList.length
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    dragArray () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      this.drag = false
      MyJobApp.paymentmethodReorder(
        this.paymentList,
        response => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  },
};
</script>
<style scoped>
</style>